<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="群发记录"
    width="70%"
    destroy-on-close
  >
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入群聊名称"
          v-model="listQuery.groupName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <el-button
          type="danger"
          size="small"
          plain
          :disabled="sels.length == 0"
          @click="del(ids)"
          >删除</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column
        prop="groupName"
        label="群聊名称"
        width="150"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="content"
        label="内容"
        min-width="280"
        align="center"
      >
        <template #default="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <template #content>
              <div :style="{ maxWidth: '500px' }">{{ scope.row.content }}</div>
            </template>
            <div>{{ scope.row.content }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="sendTime"
        label="发送时间"
        width="180"
        align="center"
      >
        <template #default="scope">
          {{ $parseTime(scope.row.sendTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template #default="scope">
          <!-- <el-button type="primary" size="small" plain>查看</el-button> -->
          <el-button
            type="danger"
            size="small"
            plain
            @click="del([scope.row.id])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="margin-top: 5px">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
          size="small"
        >
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getMsgRecodeList, delMsgRecode } from "@/api/qywx";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        groupName: "",
      },
      list: [],
      contentStyleObj: {},
      sels: [],
      total: 0,
      loading: false,
      ids: [],
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(370);
  },
  methods: {
    getList() {
      this.loading = true;
      this.dialogFormVisible = true;
      getMsgRecodeList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.sels = e;
      let ids = [];
      this.sels.map((item) => {
        ids.push(item.id);
      });
      this.ids = ids;
    },

    del(param) {
      this.$confirm("确定删除记录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMsgRecode({
          recodeId: param,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("成功删除");
            this.getList();
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
