import request from "@/utils/request";

//企业微信推送
export function sendGroupMassMsg(data) {
  return request({
    url: "api/v2/qywx/sendGroupMassMsg",
    method: "post",
    data,
  });
}

//企业微信刷新群聊
export function qywxRefresh(data) {
  return request({
    url: "api/v2/qywx/qywxRefresh",
    method: "post",
    data,
  });
}

//企业微信获取尊称
export function getComSuperiorName(data) {
  return request({
    url: "api/v2/qywx/getComSuperiorName",
    method: "post",
    data,
  });
}

//企业微信保存尊称
export function saveComSuperiorName(data) {
  return request({
    url: "api/v2/qywx/saveComSuperiorName",
    method: "post",
    data,
  });
}

//企业微信查看发送记录
export function getMsgRecodeList(data) {
  return request({
    url: "api/v2/qywx/getMsgRecodeList",
    method: "post",
    data,
  });
}
//企业微信删除记录
export function delMsgRecode(data) {
  return request({
    url: "api/v2/qywx/delMsgRecode",
    method: "post",
    data,
  });
}

//刷新部门成员列表
export function qywxCorpDeptRefresh(data) {
  return request({
    url: "api/v2/qywx/qywxCorpDeptRefresh",
    method: "post",
    data,
  });
}

//获取部门成员列表
export function qywxGetCorpDeptList(data) {
  return request({
    url: "api/v2/qywx/qywxGetCorpDeptList",
    method: "post",
    data,
  });
}

//获取configData
export function qywxGetConfigData(data) {
  return request({
    url: "/api/v2/qywx/qywxGetConfigData",
    method: "post",
    data,
  });
}

//同步通讯录
export function uploadContact(formdata) {
  return request({
    url: '/api/v2/qywx/uploadContact',
    method: 'post',
    data: formdata,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}

//激活账号
export function activateAccount(data) {
  return request({
    url: "/api/v2/qywx/activateAccount",
    method: "post",
    data,
  });
}

//企业微信首页(上边和左边)
export function getUserBehaviorData(data) {
  return request({
    url: "/api/v2/qywx/getUserBehaviorData",
    method: "post",
    data,
  });
}
//企业微信员工列表
export function getCorpUser(data) {
  return request({
    url: "/api/v2/qywx/getCorpUser",
    method: "post",
    data,
  });
}

//企业微信首页list
export function getUserListData(data) {
  return request({
    url: "/api/v2/qywx/getUserListData",
    method: "post",
    data,
  });
}

//企业微信修改群聊名称
export function updateGroupName(data) {
  return request({
    url: "/api/v2/qywx/updateGroupName",
    method: "post",
    data,
  });
}